import React, { useRef, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react";
import BrandsConfig from "../config";
import { useStores, inIframe } from "../helpers";
import { useArticleIntersectionPreload } from "../workers/ArticleCache/ArticleCacheClient";

const isInSSRIframe = new URL(window.location.href).searchParams.has('_iframe');

const Link = observer(({ to = "", className = "", children, style = undefined, state = undefined, onRedirectFinish = () => { }, disableNofollow = false, 'data-analytics-name': dataAnalyticsName = "" }) => {
  const { UIStore, AdsStore } = useStores();
  let link = document.createElement("a");
  link.setAttribute("href", to);

  // jeśli link wewnętrzny to pomijam domene
  //W SSR sprawdzamy z APP_SETTINGS
  const removeHostname = !window.SSR ? window.location.hostname === link.hostname : window.APP_SETTINGS.domain === link.hostname;
  if (removeHostname) {
    to = link.pathname;
  }

  // SSR inframe check
  const isInIframe = inIframe() || isInSSRIframe;

  const reactLinkRef = useRef();
  useArticleIntersectionPreload(reactLinkRef);

  // usuwanie spacji z linków #5796
  const toTrim = to.trim();

  const routerLinkClick = (e) => {
    UIStore.hideAllPopUps();
    UIStore.newUserInteraction();
    // @todo zrobić bez timeout, czyli po przeniesieniu do nowego routa
    // Router sam teraz to ogarnia - setTimeout(() => window.scrollTo(0, 0), 150);
    // @todo - gdzie jest zdefiniowane UIStore.hideSidebar???
    // return UIStore.hideSidebar(e);
  };

  //Auto add brands names
  const isGlob360Brand = toTrim.startsWith("http") ? [...Object.keys(BrandsConfig), "grupanatemat"].some(str => toTrim.includes(str)) : true
  window.addEventListener("redirectFinish", () => AdsStore.handleRedirectFinish(onRedirectFinish));

  return (isInIframe || toTrim.startsWith("http")) ? (
    <a
      className={`page-link ${className ?? ""}`}
      target={isInIframe ? "_top" : "_blank"}
      rel={(disableNofollow || isGlob360Brand) ? undefined : 'noopener noreferrer nofollow'}
      style={style}
      onClick={UIStore.hideSidebar}
      href={toTrim}
      data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      style={style}
      onClick={routerLinkClick}
      className={`page-link ${className ?? ""}`}
      data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}
      to={{
        pathname: toTrim,
        search: link.search,
        hash: link.hash,
        state: state
      }}
      ref={reactLinkRef}
    >
      {children}
    </RouterLink>
  );
});

Link.toString = () => ".page-link";


export default Link;
