import React from "react";
import { observer, inject } from "mobx-react";
import { styled } from "../stitches.config";

import readLaterIcon from "../assets/img/readLaterIconDark.png";
import readLaterIconAdded from "../assets/img/readLaterAdded.png";
import readLaterIconBlack from "../assets/img/readLaterIconBlack.png";
import readLaterIconBlackAdded from "../assets/img/readlater.png";

@inject("ReadLaterStore")
@observer
export default class ReadLaterWrapper extends React.Component {
  render() {
    return (
      <ReadLater
        {...this.props}
        articles={this.props.ReadLaterStore.readLaterArticles}
      />
    );
  }
}

@observer
class ReadLater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaved: false
    };
  }

  componentDidMount() {
    for (let article of this.props.articles) {
      if (article.link === this.props.link) {
        this.setState({
          isSaved: true
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      // some will return true when an element passes the test and breaks the loop, otherwise returns false
      isSaved: nextProps.articles.some(article => {
        return article.link === nextProps.link;
      })
    });
  }

  saveArticle = () => {
    const { link, image, title, ReadLaterStore } = this.props;
    const timestamp = Date.now();
    ReadLaterStore.addToReadLater({
      link,
      image,
      title,
      timestamp
    });
  };

  handleIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.isSaved) {
      this.props.ReadLaterStore.deleteFromReadLater(this.props.link);
      this.setState({ isSaved: false });
    } else {
      this.saveArticle();
      this.setState({ isSaved: true });
    }
  };

  render() {
    return (
      <ReadLaterIcon
        className="hp__read-later"
        isBloger={this.props.isBloger}
        isMovedLeft={this.props.isMovedLeft}
        isSaved={this.state.isSaved}
        onClick={this.handleIconClick}
      />
    );
  }
}

const ReadLaterIcon = styled("button", {
  position: "absolute",
  right: "0",
  top: "0",
  backgroundImage: `url("${readLaterIcon}")`,
  backgroundSize: "auto 23px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: "33px",
  width: "38px",
  zIndex: 9,
  border: "0",
  backgroundColor: "rgba(4,2,0,0.6)",
  variants: {
    "isBloger": {
      true: {
        backgroundSize: "contain",
        height: "28px",
        width: "28px",
        backgroundColor: "transparent",
        backgroundImage: `url("${readLaterIconBlack}")`
      },
      false: {}
    },
    "isSaved": {
      true: {
        backgroundImage: `url("${readLaterIconAdded}")`
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      isBloger: true,
      isSaved: true,
      css: {
        backgroundImage: `url("${readLaterIconBlackAdded}")`
      }
    }
  ]
});